import React from 'react';
import styled from 'styled-components';
import { Type } from '@mklink/react-ui.core.type';
import { Card } from '@mklink/react-ui.card.card';
import { CardLink } from '@mklink/react-ui.card.card-link';

import { projects } from '../../data/projects';

const StyledProjectContainer = styled.section`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
`;

const Projects = () => {
  return (
    <div className="content-container large-spacing">
      <Type heading="h1" fontScale="scale32">
        Current and past projects.
      </Type>
      <div className="medium-spacing">
        <Type heading="h2">Current Projects</Type>

        <StyledProjectContainer>
          {projects.map(project => {
            return (
              project.current && (
                <Card>
                  <div key={project.id} className="xsmall-spacing">
                    <div>
                      <Type fontScale="scale14" secondary>
                        {project.subTitle}
                      </Type>
                    </div>
                    <div>
                      <CardLink href={project.link} target="_blank">
                        {project.title}
                      </CardLink>
                      <Type>{project.description}</Type>
                    </div>
                  </div>
                </Card>
              )
            );
          })}
        </StyledProjectContainer>
      </div>

      <div className="medium-spacing">
        <Type heading="h2">Past Projects</Type>

        <StyledProjectContainer>
          {projects.map(project => {
            return (
              project.past && (
                <Card>
                  <div key={project.id} className="xsmall-spacing">
                    <div>
                      <Type fontScale="scale14" secondary>
                        {project.subTitle}
                      </Type>
                    </div>
                    <div>
                      <CardLink href={project.link} target="_blank">
                        {project.title}
                      </CardLink>
                      <Type>{project.description}</Type>
                    </div>
                  </div>
                </Card>
              )
            );
          })}
        </StyledProjectContainer>
      </div>
    </div>
  );
};

export default Projects;
