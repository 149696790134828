import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components';

export const StyledLink = styled(NavHashLink)`
  border: 2px solid transparent;
  border-radius: var(--small-radius);
  color: var(--primary-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--primary-font);
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 0.75rem;
  position: relative;
  padding: 0.25rem 0.75rem;
  text-align: center;
  text-decoration: none;

  /* General order is link(a), :visited, :hover, :focus, :active */
  /* Removes browser outline on hover and active states */
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    border-color: var(--hover-outlined-bg-color);
    color: var(--secondary-color);
  }

  /* Add styles for focus which includes keyboard focus */
  &.focus-visible {
    outline: var(--outline);
    outline-offset: var(--outline-offset);
  }

  /* Safari doesn't support animating the offset and doesn't support :focus-visible
  so ignores this and applies the default above */
  @media (prefers-reduced-motion: no-preference) {
    &:focus-visible {
      transition: var(--outline-transition);
    }
  }

  &:active {
    color: var(--active-color);
  }

  &.active:after {

    @media screen and (min-width: 34em) {
      display: block;
      content: '';
      border-radius: 50%;
      background-color: var(--secondary-color);
      height: 0.75rem;
      width: 0.75rem;
      position: absolute;
      bottom: -1.25rem;
      left: 38%;
    }
  }
`;
