import { RouteProps } from 'react-router-dom';
import * as paths from './paths';

import { Home } from '../pages/Home';
import { Projects } from '../pages/Projects';

interface Component {
  (): JSX.Element | null;
}

interface RouteObject extends RouteProps {
  component: Component;
  path: string;
  title?: string;
}

const routes: RouteObject[] = [
  {
    path: paths.HOME,
    component: Home,
    exact: true,
    title: 'Welcome',
  },
  {
    path: paths.PROJECTS,
    component: Projects,
    exact: true,
    title: 'Software Engineer - Projects',
  },
];

export default routes;
