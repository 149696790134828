export const projects = [
  {
    id: 'portfolio',
    title: 'Personal Portfolio',
    subTitle: 'React',
    description: 'View the repo for my personal site.',
    link: 'https://gitlab.com/mklink/megan',
    featured: true,
    current: true,
    past: false,
  },
  {
    id: 'terra-bella-tales',
    title: 'Terra Bella Tales',
    subTitle: 'React • Sanity',
    description: 'Simple blog built with Sanity Studio and React',
    link: 'https://gitlab.com/mklink/terra-bella-tales',
    featured: true,
    current: true,
    past: false,
  },
  {
    id: 'css-base',
    title: 'CSS Base',
    subTitle: 'CSS • HTML',
    description:
      'Set of CSS files to style UI elements for use in HTML projects.',
    link: 'https://gitlab.com/mklink/css-base',
    featured: false,
    current: false,
    past: true,
  },
  {
    id: 'kimis-kennels',
    title: 'Kimis Kennels',
    subTitle: 'CSS • HTML',
    description: 'Simple one page site built using CSS Base.',
    link: 'https://kimiskennels.com/',
    featured: false,
    current: false,
    past: true,
  },
];
