import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { GlobalStyles } from '@mklink/react-ui.styles.global-styles';

import routes from './constants/routes';
import { Footer, Header } from './components';

const StyledLine = styled.div`
  border-left: 2px solid var(--primary-brand100);
  position: fixed;
  bottom: 0;

  &.first-line {
    height: 20%;
    left: 2rem;

    @media screen and (min-width: 48em) {
      left: 3rem;
    }
  }

  &.second-line {
    height: 55%;
    right: 2rem;

    @media screen and (min-width: 48em) {
      right: 3rem;
    }
  }
`;

function App() {
  return (
    <div data-theme="mk">
      <GlobalStyles />

      <Header />

      <StyledLine className="first-line" />

      <Switch>
        {routes.map(({ path, exact, title, component: Component }) => (
          <Route key={path} path={path} exact={exact}>
            <Helmet title={title ? `${title} - MK` : 'Megan Klingaman'} />
            <Component />
          </Route>
        ))}
      </Switch>

      <StyledLine className='second-line' />
      <Footer />
    </div>
  );
}

export default App;
