import { Type } from '@mklink/react-ui.core.type';

interface TitleProps {
  title: string;
}
const ContentTitle = ({ title }: TitleProps) => {
  return (
    <Type heading="h2" fontColor="var(--secondary-text-color)" fontScale="scale32">
      {title}
    </Type>
  );
};

export default ContentTitle;
