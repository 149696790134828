import React from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';

import { Type } from '@mklink/react-ui.core.type';

import { Megan } from '../../../assets/';

const StyledContent = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  min-height: 90vh;
  padding-top: 2rem;
`;

const StyledTextBox = styled.div`
  color: var(--primary-text-color);
  flex-basis: 0;
  flex-grow: 999;
  min-width: 45%;

  > div > .Typist {
    color: var(--secondary-text-color);
    font-family: var(--primary-font);
    font-size: clamp(1.125rem, var(--scaler), 1.2rem);
  }
`;

const StyledImageContainer = styled.div`
  flex-basis: 22rem;
  padding-top: 2rem;
  padding-left: 2rem;

  position: relative;

  &:after {
    border: 5px solid var(--primary-color);
    content: '';
    display: block;
    height: 90%;
    left: 0;
    position: absolute;
    top: 0;
    width: 90%;
    z-index: -2;
  }

  img {
    border-radius: 4px;
  }
`;

const Intro = () => {
  return (
    <StyledContent id="home">
      <StyledTextBox className="large-spacing">
        <div>
          <Type fontScale="scale20">Hi, my name is</Type>
          <Type
            heading="h1"
            fontScale="scale40"
            fontColor="var(--secondary-text-color)"
          >
            Megan Klingaman
          </Type>
        </div>

        <div>
          <Type fontScale="scale20">I am a software engineer with a passion for UI/UX and </Type>
          <Typist>
            <span>a horseman.</span>
            <Typist.Backspace count={11} delay={350} />
            <span>an accessibility advocate.</span>
            <Typist.Backspace count={26} delay={350} />
            <span>a dog lover.</span>
            <Typist.Backspace count={12} delay={350} />
            <span>outdoorsy.</span>
          </Typist>
        </div>
      </StyledTextBox>

      <StyledImageContainer>
        <img src={Megan} alt="Megan Klingaman" />
      </StyledImageContainer>
    </StyledContent>
  );
};

export default Intro;
