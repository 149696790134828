import styled from 'styled-components';
import { Type } from '@mklink/react-ui.core.type';

export interface ExperienceProps {
  title: string;
  subTitle?: string;
}
const StyledContainer = styled.div``;

const Experience = ({ title, subTitle }: ExperienceProps) => {
  return (
    <StyledContainer>
      <Type heading="h4" fontColor="var(--primary-text-color)" fontScale='scale20'>
        {title}
      </Type>
      <Type secondary>{subTitle}</Type>
    </StyledContainer>
  );
};

export default Experience;
