import styled from 'styled-components';
import Experience from './Experience';

export interface TimelineProps {
  isFirstStep?: boolean;
  isComplete?: boolean;
}

const StyledTimeline = styled.div`
  display: grid;
  grid-auto-rows: 5rem;
  gap: 1rem;
`;

const StyledStepContainer = styled.div<TimelineProps>`
  display: flex;
  gap: 1rem;
  /* aligns the line (:after) */
  position: relative;

  &:after {
    content: ${props => (props.isFirstStep ? '' : ` ' ' `)};
    border-left: 2px solid var(--primary-color);
    left: 0.75rem;
    position: absolute;
    /* aligns the line to the middle of the circle */
    top: 1.5rem;
    /* makes sure the line takes all the space */
    height: 100%;
  }
`;

const StyledStep = styled.div`
  background-color: var(--dark100);
  border: 3px solid var(--primary-color);
  border-radius: 50%;
  height: 1.5rem;
  margin-top: 0.125rem;
  min-width: 1.5rem;
`;

const Timeline = ({ isFirstStep }: TimelineProps) => {
  return (
    <StyledTimeline>
      <StyledStepContainer>
        <StyledStep />
        <Experience
          title="SoFi - [2016 - Present]"
          subTitle="Senior Software Engineer, Front-end"
        />
      </StyledStepContainer>
      <StyledStepContainer>
        <StyledStep></StyledStep>
        <Experience
          title="Abbi Media - [2013 - 2016]"
          subTitle="Web Developer"
        />
      </StyledStepContainer>
      <StyledStepContainer>
        <StyledStep />
        <Experience
          title="Choteau Acantha - [2010 - 2013]"
          subTitle="Advertising and Design"
        />
      </StyledStepContainer>

      <StyledStepContainer>
        <StyledStep />
        <Experience
          title="Bellevue University - [ 2012 ]"
          subTitle="B.S. - Web Technologies"
        />
      </StyledStepContainer>

      <StyledStepContainer isFirstStep>
        <StyledStep />
        <Experience
          title="Northwest College - [ 2009 ]"
          subTitle="A.A.S. - Graphic Design"
        />
      </StyledStepContainer>
    </StyledTimeline>
  );
};

export default Timeline;
