import styled from 'styled-components';

import * as paths from '../../constants/paths';
import { StyledLink } from './NavLink';

const StyledHeaderContainer = styled.div`
  background-color: var(--dark200);
  position: sticky;
  top: 0;
  z-index: 10;
`;

const StyledHeader = styled.header`
  border-bottom: 2px solid var(--primary-color);
  padding: 2rem 0.75rem 1rem;

  @media screen and (min-width: 30em) {
    padding: 2rem 1rem 0.75rem 1rem;
    width: 90%;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const Header = () => {
  return (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledNav>
          <StyledLink to={`${paths.HOME}#home`}>Home</StyledLink>
          <StyledLink to={`${paths.HOME}#about`}>About</StyledLink>
          <StyledLink to={`${paths.HOME}#experience`}>Experience</StyledLink>
          <StyledLink to={`${paths.HOME}#work`}>Work</StyledLink>
          {/* <StyledLink to="#contact">Contact</StyledLink> */}
        </StyledNav>
      </StyledHeader>
    </StyledHeaderContainer>
  );
};

export default Header;
