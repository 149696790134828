// components
import { StyledContentSection } from './components/ContentSection';
import ContentTitle from './components/ContentTitle';

// page sections
import Timeline from './Experience/Timeline';
import Work from './Work/Work';
import About from './About/About';
import Intro from './Intro/Intro';
// import Contact from './Contact/Contact';

const Home = () => {
  return (
    <>
      <div className="content-container">
        <Intro />

        <StyledContentSection id="about">
          <ContentTitle title="About" />
          <About />
        </StyledContentSection>

        <StyledContentSection id="experience">
          <ContentTitle title="Experience" />
          <Timeline />
        </StyledContentSection>

        <StyledContentSection id="work">
          <ContentTitle title="Work" />

          <Work />
        </StyledContentSection>

        {/* <StyledContentSection id="contact">
          <ContentTitle title="Contact" />

          <Contact />
        </StyledContentSection> */}
      </div>
    </>
  );
};

export default Home;
