import styled from 'styled-components';
import { Type } from '@mklink/react-ui.core.type';
import { Card } from '@mklink/react-ui.card.card';
import { CardLink } from '@mklink/react-ui.card.card-link';
import { BaseLink } from '@mklink/react-ui.core.link';

import * as paths from '../../../constants/paths';

import { projects } from 'src/data/projects';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(14rem, 100%), 1fr));
  grid-gap: 1.5rem;
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: end;
`;

const Work = () => {
  return (
    <div className="medium-spacing">
      <StyledGrid>
        {projects.map(project => {
          return (
            project.featured && (
              <Card>
                <div key={project.id} className="xsmall-spacing">
                  <div>
                    <Type fontScale="scale14" secondary>
                      {project.subTitle}
                    </Type>
                  </div>
                  <div>
                    <CardLink href={project.link} target="_blank">
                      {project.title}
                    </CardLink>
                    <Type>{project.description}</Type>
                  </div>
                </div>
              </Card>
            )
          );
        })}
      </StyledGrid>

      <StyledFlex>
        <BaseLink containedLink href={paths.PROJECTS}>
          View more <span className="visually-hidden">work projects.</span>
        </BaseLink>
      </StyledFlex>
    </div>
  );
};

export default Work;
