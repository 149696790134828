import { Type } from '@mklink/react-ui.core.type';

const About = () => {
  return (
    <>
      <Type fontScale="scale20" fontColor="var(--primary-text-color)">
        I’ve spent the past 10 years across different areas of design and
        development. I started at a newspaper with design and advertising, then
        grew my development skills in digital marketing and website design.
        Currently, I work as a software engineer with a focus in UI.
      </Type>
      <Type fontScale="scale20" fontColor="var(--primary-text-color)">
        Outside of work you'll find me working with my horses and throwing a
        ball for my border collie. I love being outdoors, whether that's hiking
        or just spending time by the river with a good book.
      </Type>
      <Type fontScale="scale20" fontColor="var(--primary-text-color)">
        And if there's one thing I'm passionate about, it's horses - I could
        talk about them all day!
      </Type>
    </>
  );
};

export default About;
