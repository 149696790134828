import styled from 'styled-components';
import { Type } from '@mklink/react-ui.core.type';

const StyledFooter = styled.footer`
  background-color: var(--dark200);
  flex-shrink: 1;
  margin-top: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const Footer = () => {
    return (
      <StyledFooter>
        <div className="content-container">
          <Type textAlign="center" color="white">
            Copyright © {new Date().getFullYear()} • All Rights Reserved - Megan
            Klingaman
          </Type>
        </div>
      </StyledFooter>
    );
}

export default Footer;
