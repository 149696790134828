import styled from 'styled-components';

import { Type } from '@mklink/react-ui.core.type';

export const StyledContentSection = styled.section`
  padding-bottom: 6rem;
  padding-top: 6rem;

  > * + * {
    margin-top: 1rem;
  }
`;

export const StyledContentTitle = styled(Type)``;
